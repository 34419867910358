import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-huntsville-alabama.png'
import image0 from "../../images/cities/scale-model-of-constitution-hall-park-in-huntsville-alabama.png"
import image1 from "../../images/cities/scale-model-of-big-spring-international-park-in-huntsville-alabama.png"
import image2 from "../../images/cities/scale-model-of-weeden-house-museum-and-garden-in-huntsville-alabama.png"
import image3 from "../../images/cities/scale-model-of-downtown-huntsville,-inc.-in-huntsville-alabama.png"
import image4 from "../../images/cities/scale-model-of-world's-largest-jukebox-in-huntsville-alabama.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Huntsville'
            state='Alabama'
            image={image}
            lat='34.7303688'
            lon='-86.58610369999997'
            attractions={ [{"name": "Constitution Hall Park", "vicinity": "109 Gates Ave SE, Huntsville", "types": ["museum", "point_of_interest", "establishment"], "lat": 34.7291347, "lng": -86.58412650000002}, {"name": "Big Spring International Park", "vicinity": "200 Church St SW, Huntsville", "types": ["park", "point_of_interest", "establishment"], "lat": 34.7296111, "lng": -86.5860032}, {"name": "Weeden House Museum and Garden", "vicinity": "300 Gates Ave SE, Huntsville", "types": ["museum", "art_gallery", "point_of_interest", "establishment"], "lat": 34.7296947, "lng": -86.58226159999998}, {"name": "Downtown Huntsville, Inc.", "vicinity": "127 Washington St NE, Huntsville", "types": ["point_of_interest", "establishment"], "lat": 34.7323898, "lng": -86.58613989999998}, {"name": "World's Largest Jukebox", "vicinity": "108 Cleveland Ave NW, Huntsville", "types": ["museum", "point_of_interest", "establishment"], "lat": 34.7374915, "lng": -86.58696900000001}] }
            attractionImages={ {"Constitution Hall Park":image0,"Big Spring International Park":image1,"Weeden House Museum and Garden":image2,"Downtown Huntsville, Inc.":image3,"World's Largest Jukebox":image4,} }
       />);
  }
}